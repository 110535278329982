import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogListEntry from '../components/blog-list-entry';
import PageHeader from '../components/page-header';
import siteContent from '../config/site-content';

const AuthorPage = ({data}) => (
    <Layout>
        <PageHeader
            heading={`${siteContent.author.heading} ${data.wordpressWpUsers.name}`}
            subHeading={siteContent.author.subheading}
            pageTitle={`${data.wordpressWpUsers.name}, Author at LowGravity.pl`}
        />
        <div className="blog-listing-component">
            <div className="component-wrapper">
                <div className="blog-list">
                    {data.allWordpressPost.edges.map(({node}) => (
                        <BlogListEntry post={node} key={node.slug} />
                    ))}
                </div>
            </div>
        </div>
    </Layout>
);

export default AuthorPage;

export const pageQuery = graphql`
    query AuthorQuery($wordpress_id: Int!) {
        allWordpressPost(filter: {author: {wordpress_id: {eq: $wordpress_id}}}) {
            edges {
                node {
                    title
                    slug
                    excerpt
                    date(formatString: "Do [of] MMMM, YYYY")
                    author {
                        name
                        link
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                original {
                                    src
                                }
                            }
                        }
                    }
                    acf {
                        featured_image_background_color
                    }
                }
            }
        }
        wordpressWpUsers(wordpress_id: {eq: $wordpress_id}) {
            name
        }
    }
`;
